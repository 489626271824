import React from 'react'
// import { Link } from 'gatsby'
// import { Button, Box, Heading, Text, Divider } from 'theme-ui'
import { Heading, Text, Divider } from 'theme-ui'

/**
 * Shadow me to add your own content
 */

const styles = {
  author: {
    display: `inline-block`,
    color: `alpha`
  },
  occupation: {
    mb: 2
  },
  specialty: {
    color: `text`,
    mb: 4,
    fontWeight: 400
  }
}

export default () => (
  <>
    <Heading variant='h1'>
      <Text sx={styles.author}>Miika's Data Distillery</Text>
    </Heading>
    <Heading variant='h2' sx={styles.occupation}>
      Product Owner &amp; Decision Scientist
    </Heading>
    <Heading variant='h2' sx={styles.occupation}>
      이민호의 블로그입니다
    </Heading>
    <Divider />
    <Heading variant='h3' sx={styles.specialty}>
      Specialized in eCommerce Growth Solutions
    </Heading>
    {/* <Box variant='buttons.group'>
      <Button as={Link} to='/contact'>
        Contact Me
      </Button>
      <Button variant='white' as={Link} to='/about'>
        About Me
      </Button>
    </Box> */}
  </>
)
